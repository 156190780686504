<template>
  <v-row dense class="b-dialog-selected">
    <v-col cols="12" xl="12" lg="12" md="12" sm="12">
      <v-switch
        hide-details
        id="active"
        name="active"
        color="primary"
        class="b-dialog-selected--switch"
        label="Deseja incluir novos contratos?"
        v-model="active"
        @change="onChange"
      />
    </v-col>

    <v-dialog
      persistent
      transition="dialog-bottom-transition"
      :max-width="400"
      :retain-focus="false"
      v-model="dialog"
      v-if="dialog"
    >
      <v-form
        @submit.prevent="execute"
        ref="form"
        lazy-validation
        v-model="valid"
      >
        <v-card>
          <v-card-title class="b-dialog-selected--title">
            Selecione o(s) contrato(s)
            <v-spacer />
            <v-btn
              icon
              id="btn-close"
              name="btn-close"
              @click="closeDialog(false)"
            >
              <v-icon>fa-solid fa-xmark</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                <v-autocomplete
                  id="contracts"
                  name="contracts"
                  item-text="description"
                  item-value="id"
                  hide-details
                  dense
                  outlined
                  multiple
                  required
                  :rules="[requiredLength]"
                  :items="items"
                  v-model="contracts"
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0" class="caption">
                      {{ item.description }}
                    </span>
                    <span v-if="index === 1" class="grey--text caption ml-1">
                      (+{{ contracts.length - 1 }} contratos)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="px-4">
            <v-spacer />
            <BaseButton
              id="btn-save"
              name="btn-save"
              type="submit"
              color="primary"
              title="Salvar"
              :disabled="!valid"
            />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { MovementService } from '@/services/api/movements';

export default {
  mixins: [rulesMixin],

  props: {
    employeeId: {
      type: String,
      require: true,
      default: null
    },
    dependentId: {
      type: String,
      default: null
    },
    customerId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    items: [],
    contracts: [],
    active: false,
    dialog: false,
    valid: true
  }),

  methods: {
    async onChange() {
      if (this.active) {
        try {
          const movementService = new MovementService();
          const { status, data } = await movementService.getDetachedContracts({
            employeeId: this.employeeId,
            dependentId: this.dependentId
          });

          if (status === 200) {
            this.items = data;
            this.dialog = true;
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    },

    async execute() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const movementService = new MovementService();
        const { status } = await movementService.putBindContract({
          employeeId: this.employeeId,
          dependentId: this.dependentId,
          contracts: this.contracts
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeDialog(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    closeDialog(search) {
      this.$refs.form.reset();
      this.dialog = false;
      this.active = false;

      if (search) {
        this.$emit('updatePage');
      }
    }
  }
};
</script>
<style lang="scss">
.b-dialog-selected {
  border-bottom: 1px solid;
  padding-bottom: 10px;
  margin-bottom: 20px;

  &--switch {
    margin-top: 4px;

    .v-label {
      font-size: 14px;
    }
  }
}

.b-dialog-selected--title {
  font-weight: 400;
  font-size: 16px;
}

.theme--light {
  .b-dialog-selected {
    border-color: rgba(0, 0, 0, 0.12);
  }
}

.theme--dark {
  .b-dialog-selected {
    border-color: hsla(0, 0%, 100%, 0.12);
  }
}
</style>
